
:root {
  // --bs-toast-max-width: 750px;
  // --bs-toast-font-size: 16px;
  
  --mat-select-panel-background-color: #ffffff;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, .2), 0px 8px 10px 1px rgba(0, 0, 0, .14), 0px 3px 14px 2px rgba(0, 0, 0, .12);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, .04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, .04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, .04);
}



