@font-face {
    font-display: block;
    font-family: bootstrap-icons;
    src:  url("../assets/fonts/bootstrap-icons.woff2") format("woff2"), url("../assets/fonts/bootstrap-icons.woff") format("woff");
}

.bi:before,
[class^=bi-]:before,
[class*=" bi-"]:before {
    display: inline-block;
    font-family: bootstrap-icons !important;
    font-style: normal;
    font-weight: 400 !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: -.125em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.bi-arrow-bar-right:before {
    content: "\f114"
}

.bi-arrow-left:before {
    content: "\f12f"
}
.bi-arrow-right:before {
    content: "\f138"
}
.bi-arrow-right-short:before {
    content: "\f135"
}

.bi-bag:before {
    content: "\f179"
}
.bi-card-heading:before {
    content: "\f225"
}
.bi-cart3:before {
    content: "\f244"
}

.bi-check2:before {
    content: "\f272"
}
.bi-check-lg:before {
    content: "\f633"
}

.bi-chevron-down:before {
    content: "\f282"
}
.bi-chevron-left:before {
    content: "\f284"
}

.bi-chevron-right:before {
    content: "\f285"
}

.bi-chevron-up:before {
    content: "\f286"
}

.bi-clock:before {
    content: "\f293"
}

.bi-credit-card-2-front:before {
    content: "\f2da"
}
.bi-currency-euro:before {
    content: "\f637"
}

.bi-dash:before {
    content: "\f2ea"
}
.bi-dash-lg:before {
    content: "\f63b"
}

.bi-envelope:before {
    content: "\f32f"
}

.bi-exclamation-triangle-fill:before {
    content: "\f33a"
}
.bi-info-circle:before {
    content: "\f431"
}

.bi-eye-fill:before {
    content: "\f33e"
}

.bi-eye-slash-fill:before {
    content: "\f33f"
}

.bi-heart-fill:before {
    content: "\f415"
}
.bi-heart:before {
    content: "\f417"
}

.bi-image-alt:before {
    content: "\f428"
}
.bi-journal-arrow-down:before {
    content: "\f43a"
}
.bi-pencil-fill:before {
    content: "\f4c9"
}

.bi-plus:before {
    content: "\f4fe"
}
.bi-plus-lg:before {
    content: "\f64d"
}

.bi-printer:before {
    content: "\f501"
}

.bi-star-fill:before {
    content: "\f586"
}

.bi-star:before {
    content: "\f588"
}

.bi-three-dots-vertical:before {
    content: "\f5d3"
}

.bi-trash-fill:before {
    content: "\f5dd"
}

.bi-x:before {
    content: "\f62a"
}
.bi-x-circle:before {
    content: "\f623"
}
.bi-x-lg:before {
    content: "\f659"
}

