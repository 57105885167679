@use '@angular/material' as mat;
@import "variables.scss";

// // Custom.scss
// // Option B: Include parts of Bootstrap
// // 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";

@import "./bootstrap-icons.css";


@include mat.core();

mat-horizontal-stepper {
    .mat-horizontal-stepper-header-container {
        display: none;
    }
}

.mat-bottom-sheet-container {
  padding: 0 !important;
  @media screen and (max-width: 768px) {
    max-height: 100vh !important;
  }
  box-shadow:none !important;
}
.cdk-overlay-pane {
  /*@apply bg-transparent;*/
  @apply bg-white; // is background of multiselect dropdowns => should be white

}

.orders {
  &.tab-header-hidden {
    .mat-mdc-tab-header {
      display: none;
    }
  }
}

.modal-content{
  overflow: unset !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-labels {
  justify-content: space-around;
  display: flex;
}

.mat-mdc-tab-link {
  display: flex !important;
  flex-direction: column !important;
  width: 100px !important;
  height: 82px !important;
  min-width: auto !important;
  margin-right: 15px;
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  &.mat-tab-label-active {
    background-color: #3C4A34;
  }
}

.mat-mdc-select-panel{
    @apply bg-white;
}

mat-vertical-stepper {

  .mat-vertical-stepper-header {
    padding: 10px 8px;

    .mat-step-label {
      margin: 0px 0px 10px 0;
    }

    .mat-step {
      margin-bottom: 25px;
    }
  }


  .mat-vertical-stepper-content {
    visibility: visible !important;
    height: 100% !important;
  }

  .mat-vertical-content-container:last-of-type::before {
    content: "";
    position: absolute;
    left: 0;
    border-left-width: 0px;
    border-left-style: solid;
    border-left-color: transparent;
    height: 100%;
  }
}
