/* @import '~@angular/material/prebuilt-themes/deeppurple-amber.css'; */
/* SWIPER STYLES */
:root {
  --swiper-theme-color: #3C4A34;
  --swiper-pagination-bullet-width: 11px;
  --swiper-pagination-bullet-height: 11px;
  --swiper-pagination-bullet-horizontal-gap: 10px;
  --swiper-pagination-bottom: 0px;
}


.swiper-button-next::after,
.swiper-button-prev::after {
  content: "";
}

.swiper-button-prev,
.swiper-button-next,
.swiper-button-prev-slide,
.swiper-button-next-slide {
  @apply top-[20px] h-[44px] w-[44px] border-[3px] border-e-dark-green mr-[10px] relative left-auto right-auto rounded-full border-solid;
}

/*NGB RATING STYLES*/
ngb-rating {
  display: flex;
  flex-direction: row;
  font-size: 24px;
}

ngb-rating span {
  margin-right: 3px;
}

ngb-rating .visually-hidden {
  display: none;
}


@font-face {
  font-family: 'Nib Pro';
  src: url('assets/fonts/NibPro-Light.eot');
  src: local('Nib Pro Light'), local('assets/fonts/NibPro-Light'), url('assets/fonts/NibPro-Light.eot?#iefix') format('embedded-opentype'), url('assets/fonts/NibPro-Light.woff2') format('woff2'), url('assets/fonts/NibPro-Light.woff') format('woff'), url('assets/fonts/NibPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nib Pro';
  src: url('assets/fonts/NibPro-Italic.eot');
  src: local('Nib Pro Italic'), local('assets/fonts/NibPro-Italic'), url('assets/fonts/NibPro-Italic.eot?#iefix') format('embedded-opentype'), url('assets/fonts/NibPro-Italic.woff2') format('woff2'), url('assets/fonts/NibPro-Italic.woff') format('woff'), url('assets/fonts/NibPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nib Pro';
  src: url('assets/fonts/NibPro-SemiBold.eot');
  src: local('Nib Pro SemiBold'), local('NibPro-SemiBold'), url('assets/fonts/NibPro-SemiBold.eot?#iefix') format('embedded-opentype'), url('assets/fonts/NibPro-SemiBold.woff2') format('woff2'), url('assets/fonts/NibPro-SemiBold.woff') format('woff'), url('assets/fonts/NibPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nib Pro';
  src: url('assets/fonts/NibPro-Bold.eot');
  src: local('Nib Pro Bold'), local('assets/fonts/NibPro-Bold'), url('assets/fonts/NibPro-Bold.eot?#iefix') format('embedded-opentype'), url('assets/fonts/NibPro-Bold.woff2') format('woff2'), url('assets/fonts/NibPro-Bold.woff') format('woff'), url('assets/fonts/NibPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nib Pro';
  src: url('assets/fonts/NibPro-BlackItalic.eot');
  src: local('Nib Pro Black Italic'), local('assets/fonts/NibPro-BlackItalic'), url('assets/fonts/NibPro-BlackItalic.eot?#iefix') format('embedded-opentype'), url('assets/fonts/NibPro-BlackItalic.woff2') format('woff2'), url('assets/fonts/NibPro-BlackItalic.woff') format('woff'), url('assets/fonts/NibPro-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nib Pro';
  src: url('assets/fonts/NibPro-BoldItalic.eot');
  src: local('Nib Pro Bold Italic'), local('assets/fonts/NibPro-BoldItalic'), url('assets/fonts/NibPro-BoldItalic.eot?#iefix') format('embedded-opentype'), url('assets/fonts/NibPro-BoldItalic.woff2') format('woff2'), url('assets/fonts/NibPro-BoldItalic.woff') format('woff'), url('assets/fonts/NibPro-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nib Pro';
  src: url('assets/fonts/NibPro-LightItalic.eot');
  src: local('Nib Pro Light Italic'), local('assets/fonts/NibPro-LightItalic'), url('assets/fonts/NibPro-LightItalic.eot?#iefix') format('embedded-opentype'), url('assets/fonts/NibPro-LightItalic.woff2') format('woff2'), url('assets/fonts/NibPro-LightItalic.woff') format('woff'), url('assets/fonts/NibPro-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nib Pro';
  src: url('assets/fonts/NibPro-Black.eot');
  src: local('Nib Pro Black'), local('NibPro-Black'), url('assets/fonts/NibPro-Black.eot?#iefix') format('embedded-opentype'), url('assets/fonts/NibPro-Black.woff2') format('woff2'), url('assets/fonts/NibPro-Black.woff') format('woff'), url('assets/fonts/NibPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nib Pro';
  src: url('assets/fonts/NibPro-SemiBoldItalic.eot');
  src: local('Nib Pro SemiBold Italic'), local('assets/fonts/NibPro-SemiBoldItalic'), url('assets/fonts/NibPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'), url('assets/fonts/NibPro-SemiBoldItalic.woff2') format('woff2'), url('assets/fonts/NibPro-SemiBoldItalic.woff') format('woff'), url('assets/fonts/NibPro-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nib Pro';
  src: url('assets/fonts/NibPro-Regular.eot');
  src: local('Nib Pro Regular'), local('assets/fonts/NibPro-Regular'), url('assets/fonts/NibPro-Regular.eot?#iefix') format('embedded-opentype'), url('assets/fonts/NibPro-Regular.woff2') format('woff2'), url('assets/fonts/NibPro-Regular.woff') format('woff'), url('assets/fonts/NibPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('assets/fonts/Manrope-ExtraLight.ttf');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('assets/fonts/Manrope-Light.ttf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('assets/fonts/Manrope-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('assets/fonts/Manrope-Medium.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('assets/fonts/Manrope-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('assets/fonts/Manrope-Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Manrope';
  src: url('assets/fonts/Manrope-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply box-border;
  }

  html {
    font-family: Nib Pro;
  }

  body {
    @apply text-e-dark-green m-0 h-full font-sans text-base leading-normal;
  }

  button {
    @apply m-0 font-sans text-base leading-normal tracking-wider;
  }

  [data-type="emoji"] {
    display: inline-block;
  }

  input[type='password'],
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  input[type='multiple'],
  select,
  .mat-mdc-select {
    @apply h-[60px] px-[20px] w-full rounded-lg border-none bg-white font-sans text-base font-medium placeholder:text-center disabled:opacity-40 disabled:cursor-not-allowed;
  }

  input[type='search'] {
    @apply h-[60px] px-[20px] w-full rounded-lg bg-white font-sans text-base font-medium;
  }

  select {
    /*dropdown arrow*/
    appearance: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  textarea {
    @apply h-[60px] p-[20px] w-full rounded-lg border-e-white bg-white font-sans text-base font-medium placeholder:text-left disabled:opacity-40 disabled:cursor-not-allowed;
  }

  [multiple] {
    @apply py-[1rem];
  }

  b,
  strong {
    @apply m-0 font-bold;
  }

  i {
    @apply italic;
  }

  a {
    @apply m-0 font-sans text-base leading-tight no-underline md:leading-normal;
  }

  a p {
    @apply m-0;
  }

  p {
    @apply my-[15px] font-sans text-base font-medium md:text-base;
  }

  p a {
    @apply underline;
  }

  h1 {
    @apply leading-[36px] font-serif text-2xl font-semibold md:text-4xl md:leading-[50px];
  }

  h2 {
    @apply leading-[30px] font-serif text-xl font-semibold md:text-[36px] md:leading-[42px];
  }

  h3 {
    @apply mb-[10px] font-serif text-lg font-semibold md:text-[32px] md:leading-[38px];
  }

  h4 {
    @apply text-md leading-[24px] font-sans font-medium;
  }

  h5 {
    @apply text-[20px] leading-[28px] font-serif font-semibold;
  }

  mark {
    @apply bg-transparent text-e-orange;
  }

  ul,
  li,
  ol,
  form,
  label,
  span,
  input {
    @apply font-sans;
  }

  ul {
    @apply ml-[15px] list-disc;
  }

  small {
    @apply font-sans text-sm;
  }
}

@layer utilities {
  .container-default {
    @apply max-w-[1428px] p-[25px] mx-auto md:p-0;
  }

  .text-small {
    @apply leading-[20px] text-sm font-normal;
  }

  .icon-round {
    @apply p-[5px] flex content-center justify-center rounded-full bg-white md:p-[10px];
  }

  .form-field label {
    @apply text-e-dark-green z-[1];
  }

  .form-field input,
  .form-field select {
    @apply mt-2;
  }

  .text-shadow {
    text-shadow: 13px 32px 2px rgba(var(--swiper-theme-color), 0.8);
  }

  .button {
    @apply bg-e-dark-green text-e-reg-green py-[12px] px-[20px] text-center text-base font-medium tracking-normal disabled:bg-gray-300 disabled:text-white disabled:cursor-not-allowed disabled:placeholder-opacity-80 md:self-start;
  }

  .button-clear {
    @apply py-[12px] px-[20px] bg-transparent text-center font-medium tracking-normal md:self-start;
  }

  .button-base {
    @apply py-[12px] px-[20px] bg-e-dark-green text-e-reg-green cursor-pointer text-center text-base font-light tracking-normal no-underline transition-colors duration-100 hover:bg-e-reg-green hover:text-e-dark-green disabled:bg-gray-300 disabled:text-white disabled:cursor-not-allowed disabled:placeholder-opacity-80 md:self-start;
  }

  .button-cta {
    @apply button-base;
    @apply bg-e-orange text-white;
  }

  .button-mid {
    @apply bg-e-mid-green text-e-dark-green;
  }

  .button-reg {
    @apply bg-e-reg-green text-e-dark-green;
  }

  .button-light {
    @apply bg-e-light-green text-e-dark-green py-[12px] px-[20px] text-center text-sm font-medium tracking-normal md:self-start;
  }

  .button-white {
    @apply text-e-dark-green py-[12px] px-[20px] bg-white text-center text-sm font-medium md:self-start;
  }

  .button-small,
  .button-small p {
    @apply bg-e-mid-green rounded-full px-[15px] py-[5px];
  }


  .button-big,
  .button-big p {
    @apply bg-e-mid-green px-[15px] pt-[5px] rounded-lg font-serif text-lg font-semibold md:text-xl;
  }

  .button-card,
  .button-card p {
    @apply bg-e-dark-green font-serif;
  }

  .button-card blockquote p {
    @apply text-sm;
  }

  .error {
    @apply my-[10px] block text-e-orange;
  }

  .hint {
    @apply text-e-dark-green mt-[10px] block;
  }

  .form-field {
    @apply mt-[15px] relative;
  }

  .card ul {
    @apply list-image-[url(./assets/images/icons/checkmark.svg)] ml-[35px];
  }

  .card ul li p {
    @apply mb-[20px] ml-[20px] text-base md:mb-[25px];
  }

  .card ul li p b {
    @apply font-serif;
  }

  .chip {
    @apply pt-[4px] pb-[6px] px-[12px] mr-[10px] my-[5px] self-start whitespace-nowrap rounded-full font-medium md:py-[5px] md:px-[15px];
  }

  li.counter-item {
    @apply mb-[25px] flex flex-row;
    counter-increment: item;
  }

  li.counter-item::before {
    @apply top-[-3px] mr-[15px] bg-e-white text-e-dark-green h-[37px] min-w-[37px] content-[counter(item)] relative flex basis-auto items-center justify-center self-start rounded-full font-bold;
  }

  ol.counter {
    @apply mt-[25px] list-none;
    counter-reset: item;
  }

  input~.slide {
    @apply ml-[5px] h-5 w-10 rounded-full bg-slate-50 shadow-inner;
  }

  input~.dot {
    @apply top-[-2px] absolute -left-1 h-6 w-6 rounded-full bg-gray-400 shadow transition;
  }

  input:checked~.dot {
    @apply bg-e-dark-green;
  }

  input:checked~.slide {
    @apply bg-e-mid-green;
  }

  input:checked~.dot {
    transform: translateX(120%);
  }

  .toggle-label {
    @apply ml-5 font-medium text-gray-700;
  }

  [type="radio"],
  [type="radio"]:focus,
  [type="checkbox"] {
    @apply text-e-dark-green;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}


/* Stepper styles */
.mat-step {
  @apply mb-[15px] flex flex-col items-center;
}

.mat-step-icon {
  @apply hidden;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-link-container {
  @apply p-[25px] mt-[30px] rounded-t-lg bg-white;
}

.mat-mdc-tab-links {
  @apply items-center;
}

.mat-mdc-tab-body {
  height: 90vh !important;
}

.mat-mdc-select-panel {
  @apply bg-white;
}

.mat-mdc-dialog-container {
  max-height: unset !important;
  min-height: unset !important;
  max-width: unset !important;
  max-width: unset !important;
}

.rounded-dialog,
.rounded-dialog .mdc-dialog__surface {
  border-radius: 50px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
.mat-select-min-line,
.mat-select-value-text,
.mat-option-text {
  @apply first-letter:uppercase;
}

.mat-horizontal-content-container {
  overflow: visible;
  padding: 0 !important;
}

.mat-vertical-content-container {
  margin-left: 0 !important;
}



/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-simple-snackbar .mat-button-wrapper {
  @apply text-e-dark-green font-bold;
}

changedeliverytimeframe .mat-vertical-stepper-content {
  height: auto !important;
  visibility: visible !important;
}

.scrollcart {
  max-height: calc(100vh - 140px);
  overflow-y: scroll;
}

.sidedrawer {
  @apply h-[calc(100vh_-_137px)] min-w-[350px] top-[0px] mb-[75px] basis-[480px] hidden self-start overflow-y-scroll rounded-lg bg-white pl-1 md:block md:sticky md:top-[137px];
}